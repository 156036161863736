import React from "react"
import Icons from "../../misc/Icons"

// Data
const ContactData = {
  title: "Használná szolgáltatásunkat?",
  lead: "Amenyiben további kérdései vannak, kollégáink várják szíves megkeresését az alábbi elérhetőségeken.",
  phoneTitle: "Hívjon minket",
  phoneNumber: "+36 30 588 8865",
  phoneHours: "H-P: 9:00-17:00",
  mailTitle: "Írjon nekünk",
  mailAddress: "info@teto.envimap.hu",
  mailHours: "24 órán belül válaszolunk"
}

// Component
const Contact = () => {
  return (
    <section
      className="--contact-section"
      id="contact-section"
    >
      
      <div className="--contact-section__heading">
        <h2>{ContactData.title}</h2>
        <p>{ContactData.lead}</p>
      </div>

      <div className="--contact-section__details">
        <a
          className="contact-detail"
          href="tel:+36305888865"
        >
          <div className="contact-detail__icon">
            <Icons.Phone color="white" />
          </div>
          <div className="contact-detail__details">
            <h5>{ContactData.phoneTitle}</h5>
            <span className="span--bold">{ContactData.phoneNumber}</span>
            <span>{ContactData.phoneHours}</span>
          </div>
        </a>
        <a
          className="contact-detail"
          href={"mailto:" + ContactData.mailAddress}
        >
          <div className="contact-detail__icon">
            <Icons.Mail color="white" />
          </div>
          <div className="contact-detail__details">
            <h5>{ContactData.mailTitle}</h5>
            <span className="span--bold">{ContactData.mailAddress}</span>
            <span>{ContactData.mailHours}</span>
          </div>
        </a>
      </div>
    </section>
  )
}

export default Contact
