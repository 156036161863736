import React from "react"
import { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import Logo from "../misc/Logo"
import Icons from "../misc/Icons"
import ContainerFluid from "./Layout/ContainerFluid"
import Row from "./Layout/Row"
import { Button } from "./Molecules/Buttons";
import { ModalContext } from "./Layout/index"

// Cookies Import
import Cookies from "universal-cookie";
// Initialize cookies
const cookies = new Cookies();
// Domain option
const domain = process.env.NODE_ENV === "development" ? "localhost" : ".envimap.hu"


const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const { modalOpen } = useContext(ModalContext);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  // Initial Effect checking login state
  useEffect(() => {
    if (cookies.get("userToken", { domain: domain })) {
      setUserLoggedIn(true);
    }
  }, []);

  return (
    <>
      <ContainerFluid justSmall={false}>
        <Row>
          <Link
            className="--navbar-logo"
            to="/"
            title="EnviMAP Tető"
          >
            <Logo />
          </Link>
          
          <Link
            className="--navbar-hamburger"
            to=""
            onClick={() => {
              setIsNavExpanded(!isNavExpanded)
            }}
          >
            <Icons.Hamburger />
          </Link>

          <div className="--navbar-menu-desktop">
            {userLoggedIn
              ? <Button
                  text="Személyes fiók"
                  url="https://account.envimap.hu"
                  secondary
                />
              : <>
                  <Button
                    text="Regisztráció"
                    action={() => {modalOpen(true)}}
                  />
                  <Button
                    text="Belépés"
                    url="https://account.envimap.hu"
                    secondary
                  />
                </>
            }
          </div>

          <nav className={isNavExpanded ? "--nav-open" : ""}>
            <div className={"--navbar-menu-mobile"}>
              <div className={"--navbar-menu-mobile-close"}>
                <Link
                  className="--navbar-hamburger"
                  to=""
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded)
                  }}
                >
                  <Icons.Close />
                </Link>
              </div>
              <ul>
                <li>
                  <Button
                    text="Regisztráció"
                    action={() => {
                      modalOpen(true)
                    }}
                  />
                </li>
                <li>
                  <Button
                    text="Belépés"
                    url="https://account.envimap.hu"
                    secondary
                  />
                </li>
              </ul>
            </div>
          </nav>
        </Row>
      </ContainerFluid>
      <div
        className={
          isNavExpanded
            ? "--navbar-menu-mobile-overlay --navbar-menu-mobile-overlay-active"
            : "--navbar-menu-mobile-overlay"
        }
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
        aria-hidden="true"
      ></div>
    </>
  )
}

export default Navbar
