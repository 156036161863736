import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


// Data
const CustomersData = {
  title: "Kiknek ajánljuk?",
  customers: [
    {
      title: "Tetőfelújítással foglalkozó cégeknek",
      text: "A tetőadottságok előzetes ismeretével a tetőfedéshez, felújításhoz szükséges anyagmennyiségek gyorsan, egyszerűen tervezhetőek.",
    },
    {
      title: "Tetőburkoló anyagokat forgalmazó gyártóknak, nagykereskedéseknek",
      text: "Szolgáltatásunk igénybevételével a nagykereskedések kivitelező partnereik részére tervezési, ajánlatkészítési segítséget nyújthatnak, akár a saját törzsvásárlói kedvezményprogramjuk részeként.",
    },
    {
      title: "Tüzépeknek",
      text: "A tüzépek a tetőfelújításban gondolkozó ügyfeleik számára nyújthatnak tervezési és kalkulációs segédletet 3D épületmodelljeinkkel.",
    },
    {
      title: "Tető és napelem tisztítással, tetőfestéssel érintett cégeknek",
      text: "Az EnviMAP Tetővel készen kapja a kiválasztott épület tetőfelületeinek és tetőobjektumainak legfőbb paramétereit, így a különböző karbantartási, tisztítási munkák könnyen tervezhetővé válnak.",
    }
  ]
};

// Component
const Customers = () => {
  const images = useStaticQuery(graphql`
    query customers {
      allFile(
        filter: {
          absolutePath: { regex: "/(customers)/" }
          sourceInstanceName: { ne: "markdown" }
        }
        sort: {fields: name}
      ) {
        edges {
          node {
            childrenImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                placeholder: BLURRED
                formats: WEBP
                breakpoints: [378, 672, 980, 1200, 1568]
              )
            }
          name
            sourceInstanceName
          }
        }
      }
    }
  `)
  const customersImg = images.allFile;

  return (
    <section className="--customers-section">
      <h2>{CustomersData.title}</h2>
      <div>
        {CustomersData.customers.map((customer, index) => {
          return (
            <div
              key={index}
              className="--customers-section__item"
            >
              <div className="--customers-section__item__content-wrapper">
                <h3>{customer.title}</h3>
                <p>{customer.text}</p>
              </div>
              <div className="--customers-section__item__img">
                <GatsbyImage
                  className={"--customers-section__item__img-wrapper"}
                  image={getImage(
                    customersImg.edges[index].node.childrenImageSharp[0]
                      .gatsbyImageData
                  )}
                  loading={"lazy"}
                  alt={customersImg.edges[index].node.name}
                />
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
};

export default Customers;
