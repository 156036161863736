import React, { useContext } from "react";

import { ModalContext } from "../Layout";
import ContainerFluid from "../Layout/ContainerFluid";
import { Button } from "../Molecules/Buttons";

import Icons from "../../misc/Icons";
import DeviceFrame from "../../misc/DeviceFrame";


// Data
const HeroData = {
  title: "3D épületmodell tetőadatokkal",
  lead: "Tetőfelújítás, karbantartás, kivitelezés? Mi készen adjuk a tetőadatokat, helyszíni kiszállás nélkül tervezheti munkáit és készítheti ajánlatait.",
  keyList: [
    {
      icon: <Icons.MeasurementArea/>,
      label: "Tetőfelületek nagyságai"
    },
    {
      icon: <Icons.MeasurementLength/>,
      label: "Kategorizált élhosszak"
    },
    {
      icon: <Icons.MeasurementTilt/>,
      label: "Kitettségek és tető hajlásszögek"
    },
    {
      icon: <Icons.Add/>,
      label: "Egyedi mérési lehetőségek"
    }
  ]
};

// Component
const Hero = () => {
  const { modalOpen } = useContext(ModalContext);

  return (
    <>
      <ContainerFluid>
        <section className="--hero-section">
          <div className="--hero-section__container --container">

            <div className="--hero-section__content">
              <div className="--hero-section__content-copy">
                <h1>{HeroData.title}</h1>
                <p>{HeroData.lead}</p>
              </div>
              <div className="--hero-section__content-keylist">
                {HeroData.keyList.map((keyItem, i) => {
                  return (
                    <div
                      key={i}
                      className="--hero-section__content-keylist__item"
                    >
                      {keyItem.icon}
                      <h5>{keyItem.label}</h5>
                    </div>
                  )
                })}
              </div>
              <div className="--hero-section__content-buttons">
                <Button
                  text="Regisztráció"
                  action={() => {modalOpen(true)}}
                />
                <Button
                  text="Kapcsolatfelvétel"
                  url="#contact-section"
                  secondary
                />
              </div>
            </div>
            
            <div className="--hero-section__cover">
              <div className="--hero-section__cover-positioner">
                <DeviceFrame fit>
                  <video
                    className="child"
                    poster="Hero.jpg"
                    autoPlay
                    muted
                    loop
                    playsInline
                  >
                    <source src="Hero.mp4" type="video/mp4" />
                  </video>
                </DeviceFrame>
              </div>
            </div>

          </div>
        </section>
      </ContainerFluid>
    </>
  )
};

export default Hero;
